import { Reports } from "./reportsTypes"

export const reports: Reports[] = [
  {
    name: "Payroll Reports",
    description:
      "This report includes applicable reimbursements (or deductions in the case of AutoPay) for all employees. Note that calculations are based on expected transactions in the case of AutoPay and prior to any retroactive adjustments necessary in the case of reimbursements.",
    buttonText: "Download reports",
    reportType: "payroll_report",
  },
  {
    name: "Payroll Reconciliation Report",
    description:
      "This report includes any new or adjusted reimbursements or deductions (by employee) that apply to the same month as the Payroll report. It will be blank for a given month if there are no new or adjusted amounts.",
    buttonText: "Download reports",
    reportType: "reconciliation_payroll_report",
  },
]

export const PAYROLL_REPORT_NAME = "Payroll Reports"
export const AUTOPAY_RECONCILIATION_REPORT_NAME = "Reconciliation Reports"
export const ENROLLMENT_STATUS_REPORT_NAME = "Enrollment Status"
