import { StyledCard } from "@/components/StyledCard"
import { DisbursementAccountDetails } from "@/features/Funding/fundingTypes"
import { useRecurringPremiums } from "@/features/TCHub/tcHubService"
import { colors } from "@/theme/palette"
import { formatCents } from "@/utils/formatting"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { CardContent, CardHeader, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material"
import { format } from "date-fns"
import { calculateMinimumReserveAmount } from "../autopayUtils"
import { SkeletonSnapshotLoader } from "./SkeletonSnapshotLoader"
interface DisbursementAccountSnapshotProps {
  disbursementAccountDetails: DisbursementAccountDetails
}

export const DisbursementAccountSnapshot = ({ disbursementAccountDetails }: DisbursementAccountSnapshotProps) => {
  const notXl = useMediaQuery(theme => theme.breakpoints.down("lg"))

  const totalBalance = disbursementAccountDetails?.accountBalance + disbursementAccountDetails?.accountBalanceOnHold

  const { data: recurringPremiumCollection } = useRecurringPremiums(disbursementAccountDetails?.companyId ?? "", {
    companyId: disbursementAccountDetails?.companyId,
    coverageDate: format(
      new Date(
        new Date().getMonth() === 11 ? new Date().getFullYear() + 1 : new Date().getFullYear(),
        (new Date().getMonth() + 2) % 12,
        1
      ),
      "yyyy-MM-dd"
    ),
    status: "APPROVED",
    isPrimary: true,
    isReimbursement: false,
    limit: 1000,
    offset: 0,
  })

  if (!disbursementAccountDetails) {
    return <SkeletonSnapshotLoader />
  }

  return (
    <StyledCard sx={{ p: 2 }}>
      <CardHeader
        title={<Typography variant="h5">Disbursement Account</Typography>}
        action={<Typography variant="caption">****{disbursementAccountDetails?.accountLast4Digits}</Typography>}
      />
      <CardContent>
        <Grid
          container
          direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container>
              <Typography variant="body1" sx={{ pb: 1.5, mr: 1 }}>
                Balance
              </Typography>
              <Tooltip placement="top" title={<Typography variant="body1">The total amount of money in your account, including pending transactions.</Typography>}>
                <InfoOutlinedIcon sx={{ color: colors.silver }} />
              </Tooltip>
            </Grid>
            <Typography variant="h5" align="left">
              {totalBalance !== undefined ? formatCents(totalBalance) : "—"}
            </Typography>
          </Grid>
          <Grid item sx={{ py: notXl ? 4 : 0 }}>
            <Grid container>
              <Typography variant="body1" sx={{ pb: 1.5, mr: 1 }}>
                Available
              </Typography>
              <Tooltip placement="top" title={<Typography variant="body1">The funds you can use right now, excluding holds or pending transactions.</Typography>}>
                <InfoOutlinedIcon sx={{ color: colors.silver }} />
              </Tooltip>
            </Grid>
            <Typography variant="h5" align="left">
              {disbursementAccountDetails?.accountBalance !== undefined
                ? formatCents(disbursementAccountDetails?.accountBalance)
                : "—"}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container>
              <Typography variant="body1" sx={{ pb: 1.5, mr: 1 }}>
                Minimum Reserve
              </Typography>
              <Tooltip placement="top" title="The required minimum amount you must keep in your account.">
                <InfoOutlinedIcon sx={{ color: colors.silver }} />
              </Tooltip>
            </Grid>
            <Typography variant="h5" align="left">
              {calculateMinimumReserveAmount(recurringPremiumCollection?.recurringPremiums) !== undefined
                ? formatCents(calculateMinimumReserveAmount(recurringPremiumCollection?.recurringPremiums))
                : "—"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  )
}
