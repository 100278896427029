import { FilterDropdownField } from "@/components/FilterDropdownField"
import {
  ALL_ENROLLMENT_TYPE_OPTIONS,
  ALL_INSURANCE_TYPE_OPTIONS,
  ALL_RECURRING_PREMIUM_STATUS_OPTIONS,
} from "@/features/BenefitsElection/benefitsElectionConstants"
import { useRecurringPremiumSearch } from "@/features/TCHub/Compliance/tcHubRecurringPremiumSearch"
import { currentNextYearOptions, YES_NO_LABEL_OPTIONS } from "@/utils/util"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import { Button, Drawer, Grid, Toolbar, Typography } from "@mui/material"

export const RecurringPremiumFilterDrawer = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const { filters, setFilter } = useRecurringPremiumSearch()
  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="right">
      <Grid
        sx={{
          p: 4,
          width: 300,
        }}
      >
        <Toolbar />
        <Button variant="text" onClick={() => setOpen(false)}>
          <KeyboardArrowLeft />
          Back to Insurance Coverage
        </Button>
        <Grid container item xs={12} justifyContent="space-between" alignItems="center" mt={4}>
          <Typography variant="h1" gutterBottom>
            Filters
          </Typography>
          <Button
            variant="outlined"
            sx={{
              height: 30,
            }}
            onClick={() => {
              setFilter("planYear", undefined)
              setFilter("insuranceType", undefined)
              setFilter("olderThan10Days", undefined)
              setFilter("enrollmentType", undefined)
              setFilter("status", undefined)
              setFilter("autoPay", undefined)
            }}
          >
            Clear all
          </Button>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-start" spacing={3} mt={4}>
          <Grid item xs={12}>
            <FilterDropdownField
              options={currentNextYearOptions()}
              value={filters?.planYear ?? ""}
              onChange={planYear => setFilter("planYear", planYear)}
              label="Plan Year"
            />
          </Grid>
          <Grid item xs={12}>
            <FilterDropdownField
              options={ALL_INSURANCE_TYPE_OPTIONS}
              value={filters.insuranceType ?? ""}
              onChange={insuranceType => setFilter("insuranceType", insuranceType)}
              label="Insurance Type"
            />
          </Grid>
          <Grid item xs={12}>
            <FilterDropdownField
              options={ALL_ENROLLMENT_TYPE_OPTIONS}
              value={filters.enrollmentType ?? ""}
              onChange={enrollmentType => setFilter("enrollmentType", enrollmentType)}
              label="Enrollment Type"
            />
          </Grid>
          <Grid item xs={12}>
            <FilterDropdownField
              options={ALL_RECURRING_PREMIUM_STATUS_OPTIONS}
              value={filters.status ?? ""}
              onChange={status => setFilter("status", status)}
              label="Status"
            />
          </Grid>
          <Grid item xs={12}>
            <FilterDropdownField
              options={YES_NO_LABEL_OPTIONS}
              value={filters.autoPay ?? ""}
              onChange={autoPay => setFilter("autoPay", autoPay)}
              label="AutoPay"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <FilterDropdownField
                options={YES_NO_LABEL_OPTIONS}
                value={filters.olderThan10Days ?? ""}
                onChange={olderThan10Days => setFilter("olderThan10Days", olderThan10Days)}
                label="Older than 10 Days"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
}
