import { Box, Grid, Typography } from "@mui/material"
import { Helmet } from "react-helmet-async"
import { AuthGuard } from "../Auth/guards/AuthGuard"
import { DashboardLayout } from "../Dashboard/components/DashboardLayout"
import { EnrollmentStatusReportCard } from "./components/EnrollmentStatusReportCard"
import { PayrollReportCard } from "./components/PayrollReportCard"
import { AUTOPAY_RECONCILIATION_REPORT_NAME, ENROLLMENT_STATUS_REPORT_NAME, reports } from "./reportsConstants"
import { Reports } from "./reportsTypes"

const renderReportCard = ({ name, description, buttonText, reportType }: Reports) => {
  switch (name) {
    case ENROLLMENT_STATUS_REPORT_NAME:
      return <EnrollmentStatusReportCard name={name} description={description} buttonText={buttonText} />
    case AUTOPAY_RECONCILIATION_REPORT_NAME:
      return <PayrollReportCard name={name} description={description} buttonText={buttonText} reportType={reportType} />
    default:
      return <PayrollReportCard name={name} description={description} buttonText={buttonText} reportType={reportType} />
  }
}

export const ReportsPage = () => (
  <AuthGuard>
    <DashboardLayout>
      <Helmet title="Reports" />
      <Box>
        <Typography variant="h1">Reports</Typography>
        <Grid container xs={12} spacing={4}>
          {reports.map((r: Reports) => (
            <Grid item xs={12} md={6} key={r.name}>
              {renderReportCard({
                name: r.name,
                description: r.description,
                buttonText: r.buttonText,
                reportType: r.reportType,
              })}
            </Grid>
          ))}
        </Grid>
      </Box>
    </DashboardLayout>
  </AuthGuard>
)
