import { StyledCard } from "@/components/StyledCard"
import { RECONCILIATION_PAYROLL_REPORT } from "@/features/Documents/documentsConstants"
import { takeCommandPrimary } from "@/theme/palette"
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined"
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined"
import { Button, CardActions, CardContent, CardHeader, Typography } from "@mui/material"
import { Reports } from "../reportsTypes"
interface ReportsCardProps extends Reports {
  isButtonDisabled?: boolean
  reportType?: string
  handleButtonClick?: () => void
}

export const ReportsCard = ({
  name,
  description,
  buttonText,
  handleButtonClick,
  reportType,
  isButtonDisabled = false,
}: ReportsCardProps) => {
  const renderIcon = () =>
    reportType === RECONCILIATION_PAYROLL_REPORT ? (
      <FindInPageOutlinedIcon
        sx={{
          backgroundColor: "colors.boneChilling",
          height: "2.875rem",
          width: "2.875rem",
          padding: "0.5rem",
          color: takeCommandPrimary.main,
        }}
      />
    ) : (
      <AccountBalanceOutlinedIcon
        sx={{
          backgroundColor: "colors.boneChilling",
          height: "2.875rem",
          width: "2.875rem",
          padding: "0.5rem",
          color: takeCommandPrimary.main,
        }}
      />
    )

  return (
    <StyledCard data-qa="reports-card">
      <CardHeader avatar={renderIcon()} />
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {name}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="primary" onClick={handleButtonClick} disabled={isButtonDisabled}>
          {buttonText}
        </Button>
      </CardActions>
    </StyledCard>
  )
}
