import { AppContentWrapper } from "@/components/AppContentWrapper"
import { MainContentWrapper } from "@/components/MainContentWrapper"
import { Navbar } from "@/components/Navbar/Navbar"
import { ResourceCenterDrawer } from "@/components/ResourceCenter/ResourceCenterDrawer"
import { RootWrapper } from "@/components/RootWrapper"
import { Sidebar } from "@/components/Sidebar/Sidebar"
import { EXTERNAL_LINKS } from "@/constants"
import { colors, takeCommandPrimary } from "@/theme/palette"
import { WrapperProps } from "@/utils/types"
import { LibraryBooksOutlined } from "@mui/icons-material"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { ReactNode, useState } from "react"
import { Outlet } from "react-router-dom"

const drawerWidth = 240
const miniWidth = 79

interface BaseLayoutProps extends WrapperProps {}

interface BaseLayoutProps {
  children?: ReactNode
  menuItems: any[]
  isViewingTcHub?: boolean
}

export const BaseLayout = ({ children, menuItems, isViewingTcHub }: BaseLayoutProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [open, setOpen] = useState(!isMobile)
  const [mobileOpen, setMobileOpen] = useState(false)
  const [resourceCenterOpen, setResourceCenterOpen] = useState(false)

  const handleResourceCenterClick = () => {
    setResourceCenterOpen(true)
  }

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)
  const handleDrawerOpen = () => setOpen(true)
  const handleDrawerClose = () => setOpen(false)

  const getMainWidth = () => {
    if (isMobile) return "100%"
    if (open) return `calc(100% - ${drawerWidth}px)`
    return `calc(100% - ${miniWidth}px)`
  }

  const drawerContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box>
        {isMobile && <Toolbar />}
        <Divider />
        <Sidebar items={menuItems} />
      </Box>
      {open && (
        <Box sx={{ p: 3 }}>
          <List dense>
            <ListItemButton data-qa="footer-support-link" href={EXTERNAL_LINKS.SUPPORT} target="_blank">
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ color: takeCommandPrimary.captionGray }}>
                    Support
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton data-qa="footer-help-center-link" href={EXTERNAL_LINKS.HELP_CENTER} target="_blank">
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ color: takeCommandPrimary.captionGray }}>
                    Help Center
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton data-qa="footer-privacy-link" href={EXTERNAL_LINKS.PRIVACY} target="_blank">
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ color: takeCommandPrimary.captionGray }}>
                    Privacy
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton
              data-qa="footer-terms-of-service-link"
              href={EXTERNAL_LINKS.TERMS_OF_SERVICE}
              target="_blank"
            >
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ color: takeCommandPrimary.captionGray }}>
                    Terms of Service
                  </Typography>
                }
              />
            </ListItemButton>
          </List>
          <Button
            onClick={handleResourceCenterClick}
            data-qa="resource-center-button"
            startIcon={<LibraryBooksOutlined />}
            sx={{ my: 2, backgroundColor: colors.cloudBreak, color: colors.darkBody }}
            fullWidth
            variant="contained"
          >
            Resource Center
          </Button>
          <Typography
            variant="caption"
            data-qa="footer-copyright"
            sx={{ display: "block", textAlign: "center", px: 3 }}
          >
            © {new Date().getFullYear()} Take Command
          </Typography>
        </Box>
      )}
      <ResourceCenterDrawer open={resourceCenterOpen} onClose={() => setResourceCenterOpen(false)} />
    </Box>
  )

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar
        isMobile={isMobile}
        handleDrawerToggle={handleDrawerToggle}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        isViewingTcHub={isViewingTcHub}
        handleDrawerClose={handleDrawerClose}
      />

      {/* Sidebar for Mobile */}
      {isMobile && (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawerContent}
        </Drawer>
      )}

      {/* Sidebar for Desktop */}
      {!isMobile && (
        <Drawer
          variant="permanent"
          sx={{
            width: open ? drawerWidth : miniWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: open ? drawerWidth : miniWidth,
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
              overflowX: "hidden",
              paddingTop: "4rem",
            },
          }}
          open={open}
        >
          {drawerContent}
        </Drawer>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: getMainWidth(),
        }}
      >
        {!isMobile && !isViewingTcHub && (
          <IconButton
            size="small"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={isMobile ? handleDrawerToggle : handleDrawerOpen}
            sx={{
              position: "fixed",
              zIndex: 1200,
              backgroundColor: colors.white,
              top: 64,
              marginTop: "0.75rem",
              left: 71,
              ...(isMobile ? {} : open && { display: "none" }),
              height: "1.5rem",
              width: "1.5rem",
              padding: 2,
              boxShadow: "0px 2px 4px 0px rgba(55, 55, 55, 0.08)",
              border: `1px solid ${colors.borderGray}`,
              "&:hover": {
                backgroundColor: colors.mintGreen,
                border: 0,
                opactity: 1,
              },
            }}
          >
            <KeyboardArrowRightIcon sx={{ padding: 1.2 }} />
          </IconButton>
        )}
        {!isMobile && !isViewingTcHub && (
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              position: "fixed",
              top: 64,
              ...(open ? {} : { display: "none" }),
              backgroundColor: colors.white,
              marginTop: "0.75rem",
              zIndex: 1200,
              left: 229,
              height: "1.5rem",
              width: "1.5rem",
              padding: 2,
              boxShadow: "0px 2px 4px 0px rgba(55, 55, 55, 0.08)",
              border: `1px solid ${colors.borderGray}`,
              "&:hover": {
                backgroundColor: colors.mintGreen,
                border: 0,
                opactity: 1,
              },
            }}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRightIcon sx={{ padding: 1.2 }} />
            ) : (
              <KeyboardArrowLeftIcon sx={{ padding: 1.2 }} />
            )}
          </IconButton>
        )}
        <Toolbar />
        <RootWrapper>
          <AppContentWrapper>
            <MainContentWrapper>
              {children}
              <Outlet />
            </MainContentWrapper>
          </AppContentWrapper>
        </RootWrapper>
      </Box>
    </Box>
  )
}
