import { ReturnButton } from "@/components/Buttons"
import { DrawerForm } from "@/components/DrawerForm"
import { useAddCarrierQuestion, useUpdateCarrierQuestion } from "@/features/BenefitsElection/healthPlansService"
import { useNotifications } from "@/services/notificationService"
import { createDataQa } from "@/utils/dataQa"
import { Button, Card, CircularProgress, Grid, Stack, Toolbar, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { CarrierQuestion, CreateCarrierQuestionRequest, emptyCarrierQuestion } from "../tcHubTypes"
import { CarrierQuestionCardComponent } from "./CarrierQuestionCard"

export const baseDataQa = "carrier-question-drawer"

type EditAction = "edit"

export interface CarrierQuestionsDrawerProps {
  visible: boolean
  onClose: () => void
  currentCarrierId: string
  carrierQuestion: CarrierQuestion
  action: EditAction | "add"
  title: string
  submitButtonLabel?: string
  isCreate?: boolean
}

export const CarrierQuestionsDrawer = ({
  visible,
  onClose,
  currentCarrierId,
  carrierQuestion,
  action,
  title,
  submitButtonLabel,
}: CarrierQuestionsDrawerProps) => {
  const [validForm, setValidForm] = useState(false)
  const [isCancel, setIsCancel] = useState(false)
  const [isSave, setIsSave] = useState(false)
  const [carrierQuestionToSave, setCarrierQuestionToSave] = useState(carrierQuestion)
  const { notify } = useNotifications("carrier-question-updated-success")
  const [childUpdated, setChildUpdated] = useState(false)
  const EDIT_OPTION = "edit"
  const { mutateAsync: updateCarrierQuestion, isPending: isPendingUpdate } = useUpdateCarrierQuestion()
  const { mutateAsync: addCarrierQuestion, isPending } = useAddCarrierQuestion()

  useEffect(() => {
    setCarrierQuestionToSave(c => ({ ...c, carrierId: currentCarrierId }))
  }, [currentCarrierId])

  const handleCancelEdit = useCallback(() => {
    setIsCancel(true)
    setValidForm(false)
    setCarrierQuestionToSave(carrierQuestion)
    onClose()
  }, [setIsCancel, setValidForm, setCarrierQuestionToSave, onClose, carrierQuestion])

  const onDrawerClose = useCallback(() => {
    handleCancelEdit()
  }, [handleCancelEdit])

  const mapToCreateCarrierQuestionRequest = (createCarrierQuestion: CarrierQuestion): CreateCarrierQuestionRequest =>
    (({ id, carrierId, ...o }) => o)(createCarrierQuestion)

  useEffect(() => {
    if (isSave && validForm && childUpdated) {
      const saveData = async () => {
        try {
          const updatedCarrierQuestionToSave = {
            ...carrierQuestionToSave,
            childQuestions: carrierQuestionToSave.childQuestions?.map(child => ({
              ...child,
              parentQuestionId: child.parentQuestionId === "new" ? undefined : child.parentQuestionId,
            })),
          }

          if (action === EDIT_OPTION) {
            await updateCarrierQuestion({
              carrierId: currentCarrierId,
              carrierQuestion: updatedCarrierQuestionToSave,
            })
            notify(`Carrier question updated successfully`, "success")
          } else {
            await addCarrierQuestion({
              carrierId: currentCarrierId,
              data: mapToCreateCarrierQuestionRequest(updatedCarrierQuestionToSave),
            })
            notify(`Carrier question added successfully`, "success")
          }
          setIsSave(false)
          setCarrierQuestionToSave(emptyCarrierQuestion)
          onDrawerClose()
        } catch {
          setIsSave(false)

          notify(`Error ${action === EDIT_OPTION ? "updating" : "adding"} Carrier question`, "error")
        }
      }

      saveData()
    }
  }, [
    isSave,
    validForm,
    childUpdated,
    onDrawerClose,
    action,
    currentCarrierId,
    carrierQuestionToSave,
    updateCarrierQuestion,
    addCarrierQuestion,
    notify,
  ])

  const handleSaveClick = () => {
    setIsSave(true)
    setChildUpdated(false)
  }

  return (
    <DrawerForm
      open={visible}
      onClose={onDrawerClose}
      paperStyle={{ padding: 6, width: "93%", maxWidth: "42rem" }}
      data-qa={baseDataQa}
    >
      <Toolbar />
      <Grid container>
        <Grid item xs={12}>
          <ReturnButton
            data-qa={createDataQa(baseDataQa, "back-button")}
            text="Carrier Questions"
            handleClose={onDrawerClose}
          />
        </Grid>
        <Grid item xs={12} mt={6}>
          <Typography variant="h1" data-qa={`${baseDataQa}-title`}>
            {title}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Card variant="outlined" sx={{ p: 4, mt: 6 }}>
            <Grid item xs={12} mb={2}>
              <Typography variant="h5">Main Question</Typography>
            </Grid>
            <CarrierQuestionCardComponent
              carrierQuestion={carrierQuestionToSave}
              setCarrierQuestion={setCarrierQuestionToSave}
              isCancel={isCancel}
              isSave={isSave}
              isAChildQuestion={false}
              setValidForm={setValidForm}
              setChildUpdated={setChildUpdated}
              validForm={validForm}
            />

            <Stack direction="row" spacing={4} justifyContent="flex-end">
              <Button color="inherit" onClick={handleCancelEdit} data-qa="carrier-state-filter-cancel">
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={!validForm || isPending || isPendingUpdate}
                variant="contained"
                data-qa="carrier-state-filter-search"
                color="primary"
                onClick={handleSaveClick}
                startIcon={(isPending || isPendingUpdate) && <CircularProgress size={20} />}
              >
                {submitButtonLabel ?? "Save Changes"}
              </Button>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </DrawerForm>
  )
}
